@import "../../../../scss/variables";
@import "../../../../scss/breakpoints";
@import "../../../../scss/_typography.scss";
@import "../../../../scss/_colors.scss";

.new-booking {
  height: 100%;

  &-container {
    .new-booking-header {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .new-booking-actions {
    position: fixed;
    bottom: 0;
    left: $sidebarWidth;
    right: 0;
  }

  .no-spots-available {
    .new-booking-add-input fieldset, 
    .new-booking-add-wrapper .new-booking-add-btn {
      border-color: $red;
    }

    .new-booking-info-label {
      color: $red;
    }
  }

  .new-booking-add-wrapper {
    position: relative;
    max-width: 200px;

    &::after {
      content: "";
      clear: both;
    }

    .MuiInputBase-formControl {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .new-booking-add-input {
      float: left;
      width: 149px;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
    }

    .new-booking-add-btn {
      float: left;
      background: #fff;
      border-color: #dddee2;
      border-left-width: 0;
      font-size: 30px;
      font-weight: 800;
      min-width: 0;
      box-shadow: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      span {
        line-height: 25px;
      }
    }    
  }

  .new-booking-info-label {
    color: #aaa;
    font-size: 14px;
  }

  .download-template {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &:hover {
            cursor: pointer;
        }

        & > * {
            font-size: $font-size-smaller; 
            color: $black;
        }

        & > a::after {
            content: " ";
            margin-right: 5px;
        }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .new-booking {
    .new-booking-actions {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

div.max-width-800 {
  max-width: 800px;
}

.bulk-import-wrapper {
  div.MuiGrid-container.MuiGrid-item {
    &.padding-0 {
      padding: 0px;
    }

    &.visitor-details-title {
      padding-bottom: 0px;
      padding-top: 0px;
      margin-bottom: -12px;
    }

    &.padding-bottom-18 {
      padding-bottom: 18px;
    }
  }

  .lateral-padding-16 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .margin-bottom-neg-16 {
    margin-bottom: -16px;
  }

  .padding-top-6 {
    padding-top: 6px;
  }
  
  .dnd-accepted-files {
    padding-top: 0px;

    div > div {
      &:first-child {
        padding: 0px;
      }
  
      &:last-child {
        padding-top: 6px;
        padding-bottom: 0px;
      }
    }
  }

  h3 {
    font-size: 20px;
  }
}