@import "../../scss/styles";

.toast-alert {
  width: 300px;
  top: 50px !important;
  border: 1px solid $mostly-white;

  .MuiAlert-root {
    width: 100%;
    min-height: 55px;
    display: flex;
    align-items: center;
    background-color: $white;

    &.MuiAlert-filledInfo {
      color: $primary-blue;
      border-left: 3px solid $primary-blue;

      .title {
        color: $primary-blue;
      }
    }

    &.MuiAlert-filledSuccess {
      color: $lime;
      border-left: 3px solid $lime;
    }

    &.MuiAlert-filledError {
      color: $red;
      border-left: 3px solid $red;

      .title {
        color: $red;
      }
    }

    &.MuiAlert-filledWarning {
      color: $mostly-orange;
      border-left: 3px solid $mostly-orange;

      .title {
        color: $mostly-orange;
      }
    }

    .MuiButtonBase-root {
      .MuiIconButton-label {
        color: $dark-gray-secondary;
      }
    }

    &.MuiPaper-elevation6 {
      @include box-shadow();
    }
  }

  .toast-body {
    display: flex;
    flex-flow: column nowrap;

    .title {
      color: $lime;
      font-family: $font-family-sans-serif-bold;
      font-size: $font-size-smaller;
    }

    .message {
      color: $dark-gray-secondary;
      font-size: $font-size-small;
    }
  }
}
