@import "../../scss/styles";

.language-form-control {
    & > div {
        &:before {
            content: none;
        }

        &:after {
            border-bottom: none;
        }
    }

    .language-select-root.language-select-menu {

        &:focus {
            background: none;
        }
    }

    .language-select-icon {
        width: 20px;
        top: calc(50% - 13px);
        color: inherit;
    }
}
