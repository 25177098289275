@font-face {
  font-family: FuturaT;
  src: url("./fonts/Futura-Book.eot?4892989");
  src: url("./fonts/Futura-Book.eot?4892989") format("embedded-opentype"),
    url("./fonts/Futura-Book.woff2?4892989") format("woff2"),
    url("./fonts/Futura-Book.svg?4892989#futuraregular") format("svg");
}
@font-face {
  font-family: FuturaD;
  src: url("./fonts/Futura-Demi.eot?4892989");
  src: url("./fonts/Futura-Demi.eot?4892989") format("embedded-opentype"),
    url("./fonts/Futura-Demi.woff2?4892989") format("woff2"),
    url("./fonts/Futura-Demi.svg?4892989#futurademiregular") format("svg");
}

@mixin base-font() {
  font-family: "FuturaT";
  font-weight: 400;
  font-size: 18px;
}

@mixin headings-font() {
  font-family: "FuturaD";
  font-weight: 400;
}
