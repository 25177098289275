@import "../../scss/styles";

.closeVisitorIcon {
  border: 1px solid $very-light-gray;
  background-color: $white;
  border-radius: 1em;
  cursor: pointer;

  position: absolute;
  top: -16px;
  right: -16px;

  width: 35px !important;
  height: 35px !important;
}

div[class^='visitor-detail'],
div[class*=' visitor-detail'] {
  position: relative;
}

.visitor-dialog-buttons {
  padding-top: 16px;
  padding-bottom: 8px;
  display: flex;
  gap: 16px;

  >* {
    width: 250px !important;
  }

  &::after {
    position: absolute;
    content: " ";
    border-bottom: 1px solid #ededed;
    width: 200%;

    margin-top: -20px;
    margin-left: -39px;
  }
}

.MuiDialogContent-root {
  overflow: hidden;
}

.warning-message {
  background: #FBE5E9;
  border-radius: 4px;
  padding: 16px;
  margin: 0 16px 16px 16px;
  display: flex;

  a {
    color: unset;
    text-decoration: underline;
  }

  .icon-column {
    align-self: center;
    width: 40px;
    height: 26px;

    img {
      height: 26px;
    }
  }

  .message-column {
    width: calc(100% - 40px);
  }
}

.warning-color {
  color: $red;
}