@import "../../scss/variables";
@import "../../scss/typography";
@import "../../scss/colors";
@import "../../scss/whitespaces";

$countryFlagWrapperWidth: 106px;

.text-warning {
    color: #EA8700 !important;
}

.de-option-container {
    .de-option:nth-child(odd) {
        background-color: $mostly-white;
    }
    .de-option {
        display: flex;
        align-items: center;
    
        .code {
            width: 6em;
        }
    
        .name {
            font-weight: bold;
        }
    }

    .de-option:hover {
        background-color: $very-light-gray;
    }
}

.number-plate {
    position: relative;

    &:has(.readonly) {
        width: fit-content;
    }

    input:read-only {
        background-color: $mostly-white;
        cursor: pointer;
        width: fit-content;
        font-size: 18px;
    }

    &-wrapper {
        display: grid;
        grid-template-columns: $countryFlagWrapperWidth calc(100% - 106px);
        border: 1px solid $light-grayish-blue;
        border-radius: $borderRadius;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        &.readonly .number-plate-input {
            height: 40px;
            padding: 0 14px;
        }

        &.readonly .number-plate-seals-value,
        &.readonly .number-plate-seals-region {
            border: none !important;
        }

        &.readonly .number-plate-seals-region {
            max-width: 6rem;
        }

        &.readonly .prefix-selector {
            width: fit-content;
        }

        &.readonly {
            display: flex;
        }

        &:not(.readonly) .number-plate-seals-wrapper {
            &:focus-within {
                outline: 1px solid $lime;
                border: 1px solid $lime;
                border-bottom-width: 2px;
                border-left-width: 2px;
                border-right-width: 2px;
            }
        }

        &.error {
            border-color: $red;

            .number-plate-input,
            .prefix-selector {
                background-color: $light-red;
            }

            &:hover {
                border-color: $red;
            }

            &:focus-within {
                outline: 1px solid $red;
                border-color: $red;
            }
        }

        &.warning {
            border: 1px solid $orange;

            .number-plate-input,
            .prefix-selector {
                background-color: $light-orange;
            }

            &:hover {
                border-color: $orange;
            }
        }

        &:hover:not(.readonly) {
            border-color: #bbb;
        }

        &:focus-within:not(.readonly) {
            outline: 1px solid $lime;
            border-color: $lime;
        }

        .prefix-selector {
            display: flex;
            position: relative;
            background-color: $mostly-white;
            border-right: 1px solid $light-grayish-blue;

            .prefix-selector-wrapper {
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                width: 100%;

                .country-flag {
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    & > span {
                        font-size: $font-size-base;
                    }

                    .flag-wrapper {
                        display: flex;
                        align-items: center;
                        border-radius: 5px;
                        overflow: hidden;
                    }
                }

                
            }
        }

        .arrow-down-icon {
            width: 16px;
            height: 16px;
        }

        .prefix-selector-full {
            width: 100%;
        }

        .number-plate-input,
        .number-plate-regioninput {
            width: 100%;
            background-image: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            background-color: $white;
            padding: 4px 14px;
            font-size: $font-size-larger;
            font-family: $font-family-sans-serif-bold;
            color: $black;
            text-transform: uppercase;
            border: none;

            &:focus-within:not(.readonly) {
                outline: 1px solid $lime;
                border-color: $lime;
            }

            &::placeholder {
                color: $light-gray;
            }
        }

        .number-plate-seals-wrapper {
            display: flex;
            border-radius: $borderRadius;
            width: 100%;
            border: 1px solid #fff;

            .number-plate-seals-region {
                display: flex;
                flex: 0 1 45%;
                border: 1px solid $light-grayish-blue;
                border-radius: $borderRadius;

                &.readonly {
                    width: 80px;
                }

                &.non-readonly {
                    // hack to make arrow-down-icon absolute work
                    position: relative;
                }

                input {
                    text-transform: uppercase;


                    &:focus-within {
                        outline: none;
                        border: 0;
                    }
                }

                &:focus-within {
                    outline: none;
                }

                input::placeholder {
                    text-transform: none;
                }

                .arrow-down-icon {
                    position: absolute;
                    right: 8px;
                    top: 50%;
                    transform: translateY(-50%);

                    &.open {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }

            .number-plate-seals-column {
                display: flex;
                margin: 0 8px;
                padding-top: 3px;
            }

            .number-plate-seals-value {
                display: flex;
                flex: 0 1 55%;
                border: 1px solid $light-grayish-blue;
                border-radius: $borderRadius;

                input:focus-within {
                    outline: none;
                    border: 0;
                }

                &:focus-within {
                    outline: none;
                }
            }
        }
    }

    .number-plate-wrapper-flex {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border: 0;
        outline: none;
        border-radius: $borderRadius;

        .prefix-selector-full {
            border: 1px solid $light-grayish-blue;
            border-radius: $borderRadius;
            height: 39px;
            margin-bottom: 10px;
        }

        &:focus-visible {
            outline: none;
            border: 0;
        }

        &.error {
            border-color: $red;

            .number-plate-seals-wrapper {
                border-color: $red;

                .number-plate-regioninput {
                    background-color: $light-red;
                }

                &:focus-within {
                    outline-color: $red;
                }
            }

            .error-icon-wrapper {
                align-items: end;
                bottom: 10px;
            }

            &:focus-within {
                outline: none;
                border: 0;

                .error-icon-wrapper {
                    bottom: 11px;
                }
            }
        }
    }

    .number-plate-wrapper {

        &:focus-within {
            .warning-icon-wrapper {
                bottom: 11px;
            }
        }

        &.warning {
            .number-plate-input {
                background-color:$light-orange;
            }

            .number-plate-seals-wrapper {
                border-color: $orange;

                .number-plate-regioninput {
                    background-color: $light-orange;
                }

                &:focus-within {
                    border: 1px solid $orange;
                }
            }

            .warning-icon-wrapper {
                align-items: end;
                bottom: 10px;
            }
        }
    }

    .flag-icon {
        width: 24px;
        height: 18px;
    }

    .error-icon-wrapper {
        position: absolute;
        right: 8px;
        display: flex;
        margin-top: 8px;

        .error-icon {
            width: 18px;
            height: 18px;
            color: $red;
        }
    }

    .warning-icon-wrapper {
        position: absolute;
        right: 8px;
        display: flex;

        .warning-icon {
            width: 0.75em;
            height: 0.75em;
            color: $orange;
        }
    }

    .countries-dropdown {
        position: absolute;
        z-index: 999;
        border: 1px solid $light-grayish-blue;
        border-radius: $borderRadius;
        background-color: $white;
        box-shadow: $boxShadow;
        width: $countryFlagWrapperWidth;
        top: 44px;
        left: 0;

        &-list {
            list-style: none;
            margin: 0;
            padding: 0;
            max-height: 322px;
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .country {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 12px;
                transition: background-color 0.1s ease;

                &:hover {
                    cursor: pointer;
                    background-color: $mostly-white;
                }

                .country-flag-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }

                .check-icon {
                    color: $lime;
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
