@import "../../../../../../../../scss/typography";
@import "../../../../../../../../scss/colors";
@import "../../../../../../../../scss/whitespaces";

.product-timeframe-form {
  margin: 0 auto;
  position: relative;

  &-title {
    font-family: $font-family-sans-serif-bold;
    font-size: $font-size-large;
  }

  .product-form,
  .timeframe-form {
    padding: 28px;
  }

  &-divider {
    border-bottom: 1px solid $very-light-gray;
    width: 100%;
  }

  .no-facility-selected {
    border-radius: 6px;
    border: 1px solid $very-light-gray;
    background-color: $mostly-white;
    font-size: $font-size-smaller;
    padding: $whitespace-xs;
  }
}
