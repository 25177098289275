@import "../../scss/colors";
@import "../../scss/whitespaces";

.action-footer {
  background-color: $white;
  border-top: 1px solid $very-light-gray;

  &-container {
    padding: 1rem $whitespace-md;
    display: flex;

    &.space-between {
      justify-content: space-between;
    }
  }
}
