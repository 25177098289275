@import "./styles";

.drag-and-drop {
  padding: 1rem 0;
  height: 80%;

  &.error {
    .dnd-box {
      border-color: $red;

      .dnd-file-choose-label {
        display: none;
      }
  
      .dnd-error-label {
        display: block;
      }
    }

    .dnd-accepted-files {
      span {
        color: $red;
      }
    }
  }

  &.success {
    .dnd-box {
      .dnd-file-choose-label {
        display: none;
      }

      .dnd-success-label {
        display: block;
      }
    }
  }

  .dnd-box {
    border-radius: 8px;
    border: 2px dashed $very-light-gray;
    background-color: $mostly-white;
    overflow: hidden;
    height: 100%;
    position: relative;

    .drop-zone {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;

      &:hover {
        cursor: pointer;
      }
    }

    .dragging-img-wrapper,
    .dnd-file-dropping-label,
    .dnd-file-processing-label,
    .dnd-error-label,
    .dnd-success-label {
      display: none;
    }

    &.on-dragging,
    &.on-processing {
      border: 2px dashed $lime;
      background-color: rgba($color: $lime, $alpha: 0.1);

      .img-wrapper,
      .dnd-file-choose-label {
        display: none;
      }

      .drop-zone {
        cursor: default;
      }
    }

    &.on-dragging {
      .dragging-img-wrapper,
      .dnd-file-dropping-label {
        display: block;
      }
    }

    &.on-processing {
      .dragging-img-wrapper,
      .dnd-file-processing-label {
        display: block;
      }

      .dragging-img-wrapper {
        position: relative;

        div {
          position: relative;
          z-index: 99;
        }

        .sonar-wave {
          animation: sonarWave 2s linear infinite;
          position: absolute;
          top: calc(50% - 40px);
          left: calc(50% - 35px);
          width: 70px;
          height: 70px;
          background-color: $lime;
          border-radius: 50%;
          z-index: 9;
        }
      }
    }

    &-input {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 1rem;
      min-height: 320px;
      height: 100%;
      z-index: 999;

      & > input[type="file"] {
        display: none;
      }

      .dragging-img-wrapper {
        width: 100px;
        height: 100px;
      }

      .dnd-file-choose-label,
      .dnd-error-label,
      .dnd-success-label {
        padding-top: 1rem;
        text-align: center;
        max-width: 65%;

        .input-label {
          .dnd-link {
            color: $dark-gray-secondary;
            text-decoration: underline;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .dnd-accepted-files {
    padding-top: 1rem;
  }
}

a.link-lime {
  color: $lime;
}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
