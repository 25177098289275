.sticky-sidebar {
  position: sticky;
  max-width: 330px;
  top: 86px;

  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

  .sidebar-image {
    width: 100%;

    height: 144px;
    object-fit: cover;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .sidebar-info-wrapper {
    padding: 16px;

    .sidebar-info {
      display: flex;
      gap: 4px;
      margin: 6px 0;
    }
  }
}
