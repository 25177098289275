$white: #fff;
$mostly-white: #f9f9f9;
$red: #d90327;
$light-red: #fdf2f4;
$mostly-black: #23262c;
$black: #1c1c1c;
$very-light-gray: #ededed;
$very-light-gray-secondary: #ececec;
$light-gray: #d3d3d3;
$lime: #8bb611;
$gray: #b9b9b9;
$dark-red: #ac001d;
$dark-gray: #5b5d61;
$dark-gray-secondary: #777777;
$grayish-blue: #cbcbd1;
$light-grayish-blue: #dddee2;
$mostly-orange: #EA8700;
$primary-blue: #3333A4;
$orange: #FF9300;
$light-orange: rgba(255, 147, 1, 0.0509803922);