@import "../../scss/styles";

.nav-menu-item {
  &:not(:last-child) {
    margin-right: 5px;
    background-color: $white !important;
  }

  &:hover {
    background-color: unset !important;
  }

  .nav-link-item {
    font-size: $font-size-large;
    font-family: $font-family-sans-serif;
    text-decoration: none;
    color: $black;

    &.active {
      font-family: $font-family-sans-serif-bold;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .nav-menu-item {
    padding: 0;

    .nav-link-item {
      font-size: $font-size-base;
    }
  }
}
