@import "../../../scss/styles";

.data-col {
  padding: 0 !important;

  .available-count {
    font-size: $font-size-smaller;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
}

.data-row {
  padding: 0.8rem 0.5rem !important;
  border-bottom: 1px solid $light-grayish-blue;
  position: relative;

  &:hover {
    background-color: $mostly-white;
  }

  &:last-child {
    border-bottom: none;
  }

  &.disabled {
    color: $gray;
    background-color: $mostly-white;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: none;
    }
  }

  .disabled-row {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;

    &:first-child {
      border-radius: 4px 4px 0 0;
    }
  }
}

.parking-rights-wrapper {
  padding: 0 !important;

  .parking-rights-container {
    background-color: $white;
    border: 1px solid $light-grayish-blue;
    box-shadow: 0px 3px 6px #00000008;
    border-radius: 4px;
    width: 100%;

    .parking-rights-checkbox svg {
      width: 24px;
      height: 24px;
    }

  }

  .data-table {
    padding: 0 !important;
  }
}

@media screen and (max-width: $screen-sm) {
  .data-row {
    &:hover {
      background-color: $white;
    }
  }
}
