@import "../../../../../scss/styles";

.fmp-date-picker {
  @include box-shadow();

  .DayPicker {
    width: 339px !important;

    .DayPicker_weekHeader_li {
      width: 42px !important;

      & > small {
        text-transform: uppercase;
        color: $gray;
      }
    }

    & > div {
      & > div {
        width: 100% !important;

        .DayPickerNavigation {
          .DayPickerNavigation_button {
            position: absolute;
            top: 18px;
            line-height: 0.78;
            border-radius: 2px;
            padding: 0;
            border: 1px solid $very-light-gray;
            color: $black;

            &:first-child {
              left: 22px;
            }

            &:last-child {
              right: 22px;
            }
          }
        }
      }
    }

    .CalendarMonth_caption {
      & > strong {
        font-size: 16px;
      }
    }

    table {
      &.CalendarMonth_table {
        display: table;
        border-collapse: separate;
        box-sizing: border-box;
        border-spacing: 3px;
      }

      tbody {
        tr {
          .CalendarDay {
            border-radius: 2px;
            padding: 5px;
          }
        }
      }
    }
  }
}
