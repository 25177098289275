@import "./colors";
@import "./typography";
@import "./whitespaces";

.dark-gray {
  color: $dark-gray-secondary;
}

.light-gray {
    color: $light-gray;
    
    &-important {
      color: $light-gray !important;
    }
}

.grayish-blue {
  color: $grayish-blue;
}

.font-size-smaller {
  font-size: $font-size-smaller;
}

.hoverable {
  &:hover {
    cursor: pointer;
  }
}

.smaller-important {
  width: 16px !important;
  height: 16px !important;
}

.large-important {
  width: 20px !important;
  height: 20px !important;
}

.margin-right-xs {
  margin-right: $whitespace-xs;
}

.d-flex {
    display: flex;

    &.important {
        display: flex !important;
    }

    &.gap-3 {
        gap: 3px;
    }

    &.row-wrap {
        flex-flow: row wrap;
    }

    &.no-wrap {
        flex-wrap: nowrap;
    }

    &.justify-right {
        justify-content: flex-end;
    }

    &.justify-center {
      justify-content: center;
    }

    &.align-center {
        align-items: center;
    }

    &.flex-col {
        flex-direction: column;
    }
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.t-0 {
  top: 0;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

// text
.text-ellipsed {
  @include text-ellipsed();
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

// text-colors
.text-dark-gray-2nd {
  color: $dark-gray-secondary;
}

.text-red {
  color: $red;
}

.text-gray {
  color: $gray;
}

// text-sizes
.text-smaller {
  font-size: $font-size-smaller;
}

.text-base {
  font-size: $font-size-base;
}

// weights
.bold {
  font-family: $font-family-sans-serif-bold;
}

// heights
.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

// widths
.w-half {
  width: 50%;
}

// margins
.no-margin {
  margin: 0;

  &-important {
    margin: 0 !important;
  }
}

// paddings
.no-padding {
  padding: 0;

  &-important {
    padding: 0 !important;
  }
}

.no-padding-vertical {
  padding-top: 0;
  padding-bottom: 0;

  &-important {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.padding-xs {
  padding: $whitespace-xs;
}