@import "../../../scss/styles";

.products-container {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      font-size: $font-size-smaller;
      padding: 6px 0;

      &:not(:last-child) {
        border-bottom: 1px solid $very-light-gray;
      }

      .amount {
        display: flex;
        text-align: left;
        margin-right: 10px;
      }

      .product {
        background-color: $mostly-white;
        border: 1px solid $light-grayish-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        padding: 3px 10px;
        border-radius: 500px;
      }
    }
  }
}
