@import "./typography/Futura-font-family";
@import "./scss/styles";

html,
body {
  @include base-font();
}

body {
  height: 100vh;
  margin: 0;
  background-color: $mostly-white;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  @include headings-font();
}

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

h6 {
  font-size: $font-size-h6;
}

strong {
  font-family: $font-family-sans-serif-bold;
  font-size: $font-size-base;
}
