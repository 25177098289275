@import "../../../scss/styles";

.data-table-pagination {
  ul {
    li {
      button {
        color: $black;
        border: 1px solid $grayish-blue;
        min-width: 24px;
        font-size: $font-size-smaller;

        svg {
          color: $dark-gray;
        }

        &.MuiPaginationItem-page {
          &.Mui-selected {
            background-color: $lime;
            color: $white;
          }
        }
      }
    }
  }
}
