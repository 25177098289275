@import "../../../scss/styles";

.flex-main {
  margin-top: 80px;
  display: flex;
  flex: 1;
  min-height: calc(100vh - 80px);

  .container {
    padding: 1.5rem 0;
    max-width: 100%;
    overflow-x: clip;
  }

  .flex-sidebar {
    display: flex;
    flex-flow: column nowrap;
    width: 262px;
    flex: 0 0 auto;
  }

  @media all and (max-width: $screen-lg) {
    table {
      word-break: break-word;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .flex-main {
    .flex-sidebar {
      width: 0px;
    }
  }
}
