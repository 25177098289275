.fleet-manager-table-top-buttons {
  button + button {
    margin-left: 10px;
  }
}

svg.vehicle-icon {
  font-size: 22px;
  margin-left: 3px;
  margin-right: 11px !important;
}