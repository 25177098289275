@import "../../scss/styles";
@import "../../scss/variables";

.nav-sidebar {
  flex: 1 0 auto !important;

  .MuiDrawer-paperAnchorDockedLeft {
    border: none;
    width: $sidebarWidth;
  }
}

.nav-paper {
  display: flex !important;
  top: unset !important;
  background-color: $mostly-black !important;
}

.sidebar-menu {
  padding: 1.5rem 0 !important;

  .list-item {
    &-icon {
      color: $dark-gray;
    }

    &-text {
      color: $dark-gray;
    }

    &:hover {
      background-color: darken($color: $mostly-black, $amount: 1);
      border-right: 3px solid $red;

      .list-item-icon {
        color: $red;
      }

      .list-item-text {
        color: $white;
      }
    }

    &.active {
      background-color: darken($color: $mostly-black, $amount: 5);
      border-right: 3px solid $red;

      .list-item-icon {
        color: $red;
      }

      .list-item-text {
        color: $white;

        span {
          font-family: $font-family-sans-serif-bold !important;
        }
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .nav-sidebar {
    right: unset !important;
    top: 80px !important;
    z-index: unset !important;
  }

  .nav-paper {
    width: 70%;
  }
}
