@import "../../scss/variables";
@import "../../scss/colors";
@import "../../scss/whitespaces";
@import "../../scss/typography";

.content-card {
  border-radius: $borderRadius;
  background-color: $white;
  box-shadow: $boxShadow;

  position: relative;

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 28px;

    &.no-padding {
      padding: 0px;
    }

    .content-card-title {
      font-family: $font-family-sans-serif-bold;
      font-size: $font-size-large;
    }
  }

  #visitor-booking-facility,
  #visitor-booking-location {
    padding-bottom: 0px;
  }
}

.bulk-import-wrapper {
  padding-bottom: 12px;

  .content-card {
    width: 100%;
    max-width: 800px;
  }

  .MuiGrid-container.MuiGrid-item {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
