@import "../../scss/styles";

@mixin padding-vertical($value) {
  padding-top: $value;
  padding-bottom: $value;
}

.form-container {
  max-height: 100vh;
  background-color: $mostly-white;
  width: 675px;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  justify-content: space-between;

  .form-header {
    font-family: $font-family-sans-serif-bold;
    font-size: $font-size-large;
    background-color: $white;
    border-bottom: 1px solid $very-light-gray;

    &-content {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      @include padding-vertical(1rem);

      .close-icon-btn {
        position: absolute;
        right: 24px;
        padding: 0;

        &:hover {
          background-color: unset;
        }

        .close-icon {
          fill: $gray;
          width: 24px;
        }
      }
    }
  }

  .delegee-form {
    height: 100%;
    max-height: 85vh;
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;

    .form-body {
      @include padding-vertical(1.5rem);
      flex: 1;

      .form-input {
        width: 100%;
      }

      .focused {
        border-color: $lime;
        outline-color: $lime !important;
      }

      .error-icon {
        fill: $red;
        width: 18px;
      }

      .bulkimport-inprogress-warning {
        margin-top: 2rem;
      }
    }

    .delegation-type-wrapper {
      padding-top: 1rem;
    }
  }

  .form-footer {
    background-color: $white;
    border-top: 1px solid $very-light-gray;
    @include padding-vertical(1rem);

    &-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .form-container {
    width: 100%;
  }
}
