@import "./colors";

@mixin box-shadow() {
  box-shadow: 0px 3px 6px $very-light-gray-secondary;
}

@mixin negative-box-shadow() {
  box-shadow: 0px -1px 2px $very-light-gray-secondary;
}

@mixin text-ellipsed() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
