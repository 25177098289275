@import "./styles";

@mixin padding-vertical($value) {
    padding-top: $value;
    padding-bottom: $value;
}

.upload-view-dnd-container {
    height: 100vh;
    background-color: $mostly-white;
    width: 675px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    .upload-view-dnd-header {
        font-family: $font-family-sans-serif-bold;
        font-size: $font-size-large;
        background-color: $white;
        border-bottom: 1px solid $very-light-gray;
        @include padding-vertical(1rem);

        &-content {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;

            .close-icon-btn {
                position: absolute;
                right: 24px;
                padding: 0;

                &:hover {
                    background-color: unset;
                }

                .close-icon {
                    fill: $gray;
                    width: 24px;
                }
            }
        }

        .upload-view-dnd-header-stepper {
            padding-top: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .steps {
                width: 45%;
            }
        }
    }

    .upload-view-dnd-main {
        height: 100%;
        max-height: 85vh;
        display: flex;
        flex-flow: column nowrap;
        @include padding-vertical(1.5rem);

        .download-template {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            & > div {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                &:hover {
                    cursor: pointer;
                }

                & > * {
                    font-size: $font-size-smaller;
                    color: $black;
                }

                & > a::after {
                    content: " ";
                    margin-right: 5px;
                }
            }
        }

        .parking-rights-wrapper {
            padding-bottom: 24px !important;
        }
    }

    .upload-view-dnd-footer {
        background-color: $white; 
        
        &-content,
        &-message {
            border-top: 1px solid $very-light-gray;
            @include padding-vertical(1rem);
        }

        &-content {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }

        &-message div {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $red;
            background-color: $light-red;
            padding: 0.5rem;
            border-radius: 3px;

            svg {
                margin-right: 5px;
            }
        }
    }
}

@media screen and (max-width: $screen-sm) {
    .upload-view-dnd-container {
        width: 100%;
    }
}
