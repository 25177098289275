@import "../../scss/styles";

.nav-bar-wrapper {
  box-shadow: none !important;
  top: 0;
  left: auto;
  right: 0;
  position: fixed;

  .mobile-menu-btn {
    display: none;
  }

  .nav-toolbar {
    background-color: $white;
    border-bottom: 3px solid $red;
    min-height: 80px;
    display: flex;

    .logo-wrapper {
      width: 238px;
      display: flex;
      flex-grow: 0;
      align-self: stretch;
      align-items: center;
      padding: 0 1rem;
      border-right: 2px solid $mostly-white;

      .qpark-logo {
        height: 49px;
      }
    }

    .nav-menu {
      display: flex;
      flex: 1 1 auto;
      padding: 0 1rem;
    }

    .nav-aside {
      display: flex;
      align-items: center;

      .environment-info {
        color: $black;
      }
    }
  }
}

@media screen and (max-width: $screen-sm) {
  .nav-bar-wrapper {
    .mobile-menu-btn {
      display: flex;
    }

    .nav-toolbar {
      .logo-wrapper {
        padding: 0;
        border-right: none;
        width: unset;

        .qpark-logo {
          height: 34px;
        }
      }

      .nav-menu {
        padding: 0;
      }
    }
  }
}
