$font-family-sans-serif: "FuturaT", Arial, Helvetica, sans-serif;
$font-family-sans-serif-bold: "FuturaD", Arial, Helvetica, sans-serif;

$font-size-base: 18px;
$font-size-large: 20px;
$font-size-larger: 24px;
$font-size-extra-large: 28px;
$font-size-smaller: 16px;
$font-size-small: 14px;
$font-size-extra-small: 12px;
$font-size-h1: 32px;
$font-size-h2: 28px;
$font-size-h3: 24px;
$font-size-h4: 20px;
$font-size-h5: 18px;
$font-size-h6: 12px;
