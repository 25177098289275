@import "../../../../scss/styles";

.carousel-slider {
  position: relative;
  width: 100%;
  min-height: 200px;
  height: 100%;
  margin: 0 auto;

  &-wrapper {
    display: flex;
    position: relative;
    flex-wrap: wrap;

    &.slide {
      flex-wrap: nowrap;
      position: absolute;
      transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
  }

  .btn-previous,
  .btn-next {
    z-index: 1;
    position: absolute;
    top: 70px;
  }

  .btn-previous {
    left: 0;
  }

  .btn-next {
    right: 0;
  }
}
