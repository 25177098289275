@import "../../scss/styles";

.context-menu-wrapper {
  li:not(:last-child) {
    border-bottom: 1px solid $very-light-gray;
  }
}

.table-filters {
  .text-filter-control > :first-child,
  button,
  .refreshIcon,
  .dropdown-filter-control {
    height: 35px;
  }

  input {
    padding-right: 25px;
  }
  
  .filled-dropdown-icon {
    right: 24px;
  }

  .horizontal-scroll-wrapper {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    margin-left: 15px;
    margin-right: 15px;
    
    & > div {
      min-width: 200px;
      width: min-content;
      flex-shrink: 0;
      flex-basis: unset;
      max-width: unset;
      z-index: 0;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  #filters-right-arrow {
    transform: rotate(90deg);
    right:-10px;
    opacity: 1;
  }
}

.vb-filter-button {
  margin-top: auto;
  margin-bottom: auto;
  width: inherit;
}

.MuiTableRow-root:hover {
  .cancelButton {
    opacity: 100;
  }
}

.menu-action-button svg {
  font-size: 28px !important;
}