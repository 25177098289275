@import "../../../../scss/colors";
@import "../../../../scss/whitespaces";

.qp-select-card {
  display: grid;
  grid-template-columns: 40px calc(100% - 40px);
  border: 1px solid $light-gray;
  border-radius: 6px;
  padding: $whitespace-xs;
  transition: border-color 0.2s ease;
  background-color: $white;
  align-items: center;

  &.active {
    border-color: $lime;

    .qp-select-card-check {
      .check-icon-wrapper {
        border-color: $lime;
        background-color: rgba($color: $lime, $alpha: 0.08);

        & > .check-icon {
          color: $lime;
        }
      }
    }
  }

  &:hover {
    border-color: $lime;
    cursor: pointer;

    .qp-select-card-check {
      .check-icon-wrapper {
        border-color: $lime;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &-check {
    .check-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid $light-gray;
      width: 26px;
      aspect-ratio: 1 / 1;
      background-color: $white;
      transition: border-color 0.2s ease;
      overflow: hidden;

      & > .check-icon {
        width: 20px;
        aspect-ratio: 1 / 1;
        color: transparent;
      }
    }
  }
}
